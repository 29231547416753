"use client";

import { useTheme } from "next-themes";
import { Toaster as Sonner } from "sonner";
type ToasterProps = React.ComponentProps<typeof Sonner>;
const Toaster = ({
  ...props
}: ToasterProps) => {
  const {
    theme = "system"
  } = useTheme();
  return <Sonner theme={theme as ToasterProps["theme"]} className="toaster group" icons={{
    success: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5071_45417)">
              <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_5071_45417">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>,
    warning: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.47012 21.0002H19.5301C21.0701 21.0002 22.0301 19.3302 21.2601 18.0002L13.7301 4.99018C12.9601 3.66018 11.0401 3.66018 10.2701 4.99018L2.74012 18.0002C1.97012 19.3302 2.93012 21.0002 4.47012 21.0002ZM12.0001 14.0002C11.4501 14.0002 11.0001 13.5502 11.0001 13.0002V11.0002C11.0001 10.4502 11.4501 10.0002 12.0001 10.0002C12.5501 10.0002 13.0001 10.4502 13.0001 11.0002V13.0002C13.0001 13.5502 12.5501 14.0002 12.0001 14.0002ZM13.0001 18.0002H11.0001V16.0002H13.0001V18.0002Z" fill="white" />
          </svg>,
    info: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5071_45426)">
              <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17ZM13 9H11V7H13V9Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_5071_45426">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
  }} toastOptions={{
    classNames: {
      toast: "group toast text-white group-[.toaster]:bg-red-500 group-[.toaster]:text-foreground  group-[.toaster]:shadow-lg",
      title: "text-white",
      description: "group-[.toast]:text-muted-foreground",
      actionButton: "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
      cancelButton: "group-[.toast]:bg-transparent group-[.toast]:text-muted-foreground group-[.toast]:-mr-5",
      success: "group toast bg-[#178F57] text-white text-base font-medium group-[.toaster]:bg-[#178F57] data-[state=success]:bg-[#178F57] group-[.toaster]:text-white dark:group-[.toaster]:text-white ",
      warning: "group toast group-[.toaster]:bg-yellow group-[.toaster]:text-yellow-600 dark:group-[.toaster]:text-foreground group-[.toaster]:shadow-lg",
      info: "group toast group-[.toaster]:bg-blue group-[.toaster]:text-blue-600 dark:group-[.toaster]:text-foreground group-[.toaster]:shadow-lg"
    }
  }} {...props} data-sentry-element="Sonner" data-sentry-component="Toaster" data-sentry-source-file="sonner.tsx" />;
};
export { Toaster };